import React from "react";
import { useSelector } from "react-redux";
import PollResponseForm from "./PollResponseForm";
import PollResultsContent from "./PollResultsContent";


const PollSubject = ({ poll }) => {

  const { takenByUser, closesAt } = poll.attributes;
  
  const PollContent =
    takenByUser || moment(closesAt).isBefore(moment())
      ? PollResultsContent
      : PollResponseForm;
  
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body" style={{ padding: 12 }}>
        <div style={{ maxWidth: 720 }}>
          <PollContent poll={poll} />
        </div>
      </div>
    </div>
  );
};

export default PollSubject;
