import React from "react";
import { Row, Col, Visible } from "react-grid-system";
import { Provider } from "react-redux";
import configureStore from "../../configureStore";
import SideCards from "../General/SideCards";
import VendorProductsIndex from "./Products/Index";
import PopularVendors from "./general/PopularVendors";

const store = configureStore();

const ProductCategory = ({ productCategorySlug }) => {
  return (
    <Provider store={store}>
      <Row>
        <Visible lg xl xxl>
          <Col lg={8} style={{ paddingRight: 0 }}>
            <VendorProductsIndex productCategorySlug={productCategorySlug} />
          </Col>
          <Col md={12} lg={4} style={{ paddingLeft: 0 }}>
            <SideCards>
              <div className="kt-portlet" style={{ height: "auto" }}>
                <div>
                  <div className="kt-portlet__body kt-portlet__body--fit">
                    <PopularVendors />
                  </div>
                </div>
              </div>
            </SideCards>
          </Col>
        </Visible>
        <Visible xs sm md>
          <Col xs={12} lg={8}>
            <VendorProductsIndex productCategorySlug={productCategorySlug} />
          </Col>
        </Visible>
      </Row>
    </Provider>
  );
};

export default ProductCategory;
