import React from "react";
import { createUseStyles } from "react-jss";
import { Row, Col } from "react-grid-system";
import FetchDataLink from "../../general/FetchDataLink";

const useStyles = createUseStyles({
  unorderedList: {
    padding: "4px 0 0 12px",
    listStyleType: "none",
    "& li+li": {
      paddingTop: "10px",
    },
  },
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
    color: "#263238",
    marginBottom: 16,
  },
  sectionSubtitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    color: "#263238",
    marginBottom: 10,
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    color: "#455A64",
  },
  listItem: {
    fontSize: 18,
    lineHeight: "20px",
    "& a": {
      color: "#367BA6",
    },
  },
  listIcon: {
    marginTop: 2,
    fontSize: 22,
    color: "#000000",
    marginRight: 8,
    width: 22,
    textAlign: "center",
  },
});

const About = ({ vendorProduct }) => {
  const classes = useStyles();
  const {
    description,
    linkedinUrl,
    facebookUrl,
    twitterUrl,
    websiteLink,
    linkedinLink,
    facebookLink,
    twitterLink,
    fetchPhoneLink,
    fetchEmailLink,
  } = vendorProduct.attributes;

  return (
    <div
      style={{
        marginBottom: 20,
        paddingBottom: 10,
        borderBottom: "1px dashed #8c979a",
      }}
    >
      <div className={classes.sectionTitle} id="about">
        About
      </div>
      <Row>
        <Col xs={12} md={8}>
          <div className={classes.sectionSubtitle}>Product Description</div>
          <p
            className={classes.text}
            style={{
              padding: "0 24px",
              fontSize: "1.2857142857142858rem",
              lineHeight: "2.2857142857142856rem",
            }}
          >
            {description}
          </p>
        </Col>
        <Col xs={12} md={2}>
          <div className={classes.sectionSubtitle}>Contact</div>
          <ul className={classes.unorderedList}>
            <li className={classes.listItem}>
              <i className={`fa fa-globe-americas ${classes.listIcon}`} />
              <a href={websiteLink} target="_blank" rel="noopener noreferrer">
                Website
              </a>
            </li>
            {fetchPhoneLink && (
              <FetchDataLink
                icon={"phone"}
                text={"Phone"}
                link={fetchPhoneLink}
              />
            )}
            {fetchEmailLink && (
              <FetchDataLink
                icon={"envelope"}
                text={"Email"}
                link={fetchEmailLink}
              />
            )}
          </ul>
        </Col>
        <Col xs={12} md={2}>
          <div className={classes.sectionSubtitle}>Connect</div>
          <ul className={classes.unorderedList}>
            {linkedinUrl && (
              <li className={classes.listItem}>
                <i className={`fab fa-linkedin ${classes.listIcon}`} />
                <a
                  href={linkedinLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
            )}
            {facebookUrl && (
              <li className={classes.listItem}>
                <i className={`fab fa-facebook-square ${classes.listIcon}`} />
                <a
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
            )}
            {twitterUrl && (
              <li className={classes.listItem}>
                <i className={`fab fa-twitter-square ${classes.listIcon}`} />
                <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
            )}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default About;
