import React from "react";
import { Row, Col } from "react-grid-system";
import { Provider } from "react-redux";
import configureStore from "../../configureStore";
import Show from "./Products/Show";

const store = configureStore();

const ProductCategory = ({
  productCategorySlug,
  vendorProductSlug,
  vendorProductId,
  reviewIndexUrl,
  viewAllReviews,
  specificReviewSlug,
}) => {
  return (
    <Provider store={store}>
      <Row>
        <Col xs={12}>
          <Show
            productCategorySlug={productCategorySlug}
            vendorProductSlug={vendorProductSlug}
            vendorProductId={vendorProductId}
            reviewIndexUrl={reviewIndexUrl}
            viewAllReviews={viewAllReviews}
            specificReviewSlug={specificReviewSlug}
          />
        </Col>
      </Row>
    </Provider>
  );
};

export default ProductCategory;
