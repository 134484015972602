import React from "react";
import { connect } from "formik";

import FormFooter from "../../General/FormPage/FormFooter";

import classnames from "classnames";

const SubmitButton = connect(({ formik: { handleSubmit } }) => (
  <button
    className={classnames("btn", "btn-secondary")}
    onClick={handleSubmit}
    type="button"
    style={{marginLeft: 10}}
  >
    Create Poll
  </button>
));

const CancelButton = () => {
  const handleClick = () => {
    window.location.href = `/polls`;
  };

  return (
    <button
      className={classnames("btn", "btn-outline-brand-secondary")}
      onClick={handleClick}
      type="button"
    >
      Cancel
    </button>
  );
};

const Footer = ({ formik }) => {
  const { submitCount, errors } = formik;
  const showErrorMessage = Boolean(submitCount && Object.keys(errors).length);

  return (
    <FormFooter
      errorMessage={
        showErrorMessage && "Please review the fields highlighted in red."
      }
      actions={
        <React.Fragment>
          <CancelButton />
          <SubmitButton />
        </React.Fragment>
      }
    />
  );
};

export default connect(Footer);
