import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { map, orderBy } from "lodash";

import {
  ConnectedTextField,
  ConnectedDropdown,
  ConnectedWysiwyg,
} from "../../General/FormInputs/ConnectedInputs";
import { getSubscribedGroups } from "../../../selectors/groupSelectors";

const Subject = () => <ConnectedTextField id="subject" label="Subject" />;

const Body = () => (
  <ConnectedWysiwyg id="body" label="Body" uploadFolder="posts" hashtaggable />
);

const Group = () => {
  const subscribedGroups = useSelector(getSubscribedGroups);

  const options = useMemo(
    () =>
      orderBy(
        map(
          subscribedGroups,
          ({ id, attributes: { isNational, name, isInternational } }) => {
            const label = isNational || isInternational ? name : `${name} Only`;

            return { value: id, label };
          }
        ),
        "label",
        "asc"
      ),
    [subscribedGroups]
  );

  return (
    <ConnectedDropdown
      id="groupId"
      options={options}
      label="Who can view this topic?"
      styles={{ container: { maxWidth: 500 } }}
    />
  );
};

const FormFields = () => (
  <div style={{ padding: 24 }}>
    <Subject />
    <Body />
    <Group />
  </div>
);

export default FormFields;
