import { object, string, boolean, array } from "../../../util/yup.js";
import moment from "moment";

export const initialValues = {
  question: "",
  choices: ["", ""],
  multiSelect: false,
  closesAt: moment().add(2, "months").format("YYYY-MM-DD"),
  groupId: "",
};

export const validationSchema = object().shape({
  question: string()
    .required("Cannot be blank")
    .max(150, "Must be less than 150 characters"),
  choices: array()
    .of(
      string()
        .required("Cannot be blank")
        .max(60, "Must be less than 60 characters")
        .uniqWithinParent("Must be unique")
    )
    .min(2, "Must have at least 2 options"),
  multiSelect: boolean().required("Cannot be blank"),
  groupId: string().required("Cannot be blank"),
});
