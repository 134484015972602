import React from "react";
import map from "lodash/map";

import Section from "./results/Section";
import Category from "./results/Category";
import Product from './results/Product';
import { styles } from "./utils";

const Results = ({ show, products, categories }) => {
  const classes = styles({ show });

  const sections = [
    {
      title: "Categories",
      items: categories,
      Component: Category,
    },
    {
      title: "Vendors",
      items: products,
      Component: Product,
    },
  ];

  return (
    <div className={classes.container}>
      <div className="kt-portlet kt-portlet__body">
        {map(sections, (section, index) => (
          <Section {...{ key: index, ...section }} />
        ))}
      </div>
    </div>
  );
};

export default Results;
