import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import moment from "moment";

import AvatarIcon from "../../General/AvatarIcon";
import ProfileHover from "../../Shared/ProfileHover/ProfileHover";

import classnames from "classnames";

const useStyles = createUseStyles({
  container: {
    borderTop: "1px solid #ECEDF2",
  },
  descriptionIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  statusItemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  statusItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
  },
  statusIcon: {
    height: 15,
    width: 15,
    marginRight: 3,
  },
  "@media screen and (max-width: 600px)": {
    descriptionIcons: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});

const Topic = ({ id }) => {
  const classes = useStyles();
  const topic = useSelector((state) => state.topics.items[id], shallowEqual);

  const group = useSelector(
    (state) => topic && state.groups.items[topic.attributes.groupId]
  );

  const user = useSelector(
    (state) => topic && state.users.items[topic.attributes.userId],
    shallowEqual
  );

  if (!(topic && group && user)) {
    return null;
  }

  const { urlPath, subject, lastActivityAt, commentsCount, userId } =
    topic.attributes;

  const { firstName, lastName, companyName } = user.attributes;

  const { name: groupName } = group.attributes;

  return (
    <div className={classes.container}>
      <a
        href={urlPath}
        className="kt-notification-v2__item"
        style={{ padding: "2rem" }}
      >
        <ProfileHover {...{ userId }}>
          <AvatarIcon avatarId={user.id} height={50} />
        </ProfileHover>
        <div className="kt-notification-v2__itek-wrapper">
          <div
            className="kt-notification-v2__item-title"
            style={{ color: "#F35B5E", fontSize: 16 }}
          >
            {subject}
          </div>
          <div
            className={`kt-notification-v2__item-desc ${classes.descriptionIcons}`}
            style={{
              color: "#212121",
              fontSize: 14,
            }}
          >
            <div style={{ marginRight: 15 }}>
              <i className="la la-book" /> {groupName}
            </div>
            <ProfileHover {...{ userId }}>
              <div>
                <i className="la la-user"></i> {firstName} {lastName} -{" "}
                {companyName}
              </div>
            </ProfileHover>
          </div>
        </div>
        <div className={classes.statusItemsContainer}>
          <div
            className={classes.statusItem}
            style={{
              color: "#212121",
            }}
          >
            <i className="la la-clock-o" className={classes.statusIcon}></i>
            {moment(lastActivityAt).fromNow()}
          </div>
          <div
            className={classes.statusItem}
            style={{
              color: "#357BA6",
              fontWeight: 600,
            }}
          >
            <i
              className={classnames("la", "la-comments-o", classes.statusIcon)}
            />
            {commentsCount} Comment{commentsCount !== 1 && "s"}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Topic;
