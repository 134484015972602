import React from "react";
import { useDispatch } from "react-redux";

import { destroyResponse } from "../../../../../actions/pollActions";

import moment from "moment";
import FooterButton from "../FooterButton";

const UndoButton = ({ poll }) => {
  const dispatch = useDispatch();

  const { id, closesAt } = poll.attributes;

  if (moment(closesAt).isBefore(moment())) {
    return null;
  }

  const handleClick = () => dispatch(destroyResponse(id));

  return <FooterButton onClick={handleClick} buttonText="Undo" />;
};

export default UndoButton;
