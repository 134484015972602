import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import map from "lodash/map";
import reverse from "lodash/reverse";

import { fetchVendorProducts } from "../../../actions/vendorProductActions";

import IndexItem from "./IndexItem";
import Search from "../Search";
import Order from "../../General/Order";

import {
  orderItems,
  PRODUCT_CATEGORY_SORT_OPTIONS,
  MOST_REVIEWS,
  defaultValue,
} from "../general/orderHelpers";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    transition: "height 0.3s ease",
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
  },
  secondPartHeader: { display: "flex" },
  unorderedList: {
    padding: "0",
    listStyleType: "none",
    "& li+li": {
      marginTop: "10px",
    },
  },
  "@media only screen and (max-width: 950px)": {
    header: {
      flexDirection: "column",
    },
    secondPartHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 12,
    },
  },
});

const Index = ({ productCategorySlug }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [order, setOrder] = useState(MOST_REVIEWS);

  const { items, loaded } = useSelector((state) => state.vendorProducts);

  useEffect(() => {
    if (!loaded) {
      setFetching(true);
      dispatch(fetchVendorProducts(productCategorySlug)).then(() => {
        setFetching(false);
      });
    }
  }, [loaded, dispatch]);

  return (
    <React.Fragment>
      <div className={`kt-portlet kt-portlet__body ${classes.header}`}>
        <Search />
        <div className={classes.secondPartHeader}>
          <Order
            setOrder={setOrder}
            defaultValue={defaultValue}
            options={PRODUCT_CATEGORY_SORT_OPTIONS}
            isSearchable={false}
          />
          <a
            style={{
              fontSize: 14,
              lineHeight: "38px",
              fontWeight: 300,
              marginLeft: 24,
            }}
            href="/vendors/suggest"
          >
            Missing a vendor?
          </a>
        </div>
      </div>
      <ul className={classes.unorderedList}>
        {map(orderItems(order, items), (product) => (
          <IndexItem product={product} key={product.id} />
        ))}
      </ul>
    </React.Fragment>
  );
};

export default Index;
