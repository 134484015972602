import React from "react";

import { styles } from "./utils";

const Input = ({ query, setOpen, setQuery, loaded}) => {
  const classes = styles({});

  return (
    <React.Fragment>
      <i className={`fas fa-search ${classes.inputIcon}`} />
      <input
        value={query}
        placeholder="Search by Category or Vendor"
        onFocus={() => setOpen(true)}
        onChange={(e) => setQuery(e.target.value)}
        className="form-control"
        style={{ paddingLeft: 36 }}
      />
      {query &&
        query.length > 0 &&
        (loaded ? (
          <i
            onClick={() => setQuery("")}
            className={`fas fa-times ${classes.inputClose}`}
          />
        ) : (
          <i className={`fas fa-spinner fa-spin ${classes.inputClose}`} />
        ))}
    </React.Fragment>
  );
};

export default Input;
