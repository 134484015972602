import React from "react";
import { useSelector } from "react-redux";

import PopoverHover from "../../General/PopoverHover";
import ProfileHoverContents from "./ProfileHoverContents";

const ProfileHover = ({ children, userId }) => {
  const currentUserId = useSelector((state) => state.settings.attributes.id);

  return currentUserId === userId ? (
    children
  ) : (
    <PopoverHover
      PopoverContent={() => <ProfileHoverContents {...{ userId }} />}
      placement={"bottom-start"}
    >
      {children}
    </PopoverHover>
  );
};

export default ProfileHover;
