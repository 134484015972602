import React from "react";
import withGlobalProviders from "../General/withGlobalProviders";
import { useDispatch } from "react-redux";

import { Formik } from "formik";
import FormPageLayout from "../General/FormPage/FormPageLayout";
import Header from "./create/Header";
import FormFields from "./create/FormFields";
import Footer from "./create/Footer";

import { createPoll } from "../../actions/pollActions";
import {
  initialValues,
  validationSchema,
} from "./create/fieldHelpers";

const CreatePoll = ({}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values, context) =>
    dispatch(createPoll(values))
      .then((createdPoll) => {
        window.location.href = createdPoll.attributes.urlPath;
      })
      .catch(() => {
        context.setSubmitting(false);
      });

  return (
    <FormPageLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <React.Fragment>
            <Header />
            <FormFields />
            <Footer />
          </React.Fragment>
        )}
      </Formik>
    </FormPageLayout>
  );
};

export default withGlobalProviders(CreatePoll);
