import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classnames from "classnames";
import { createUseStyles } from "react-jss";

import { destroyPoll } from "../../../actions/pollActions";

const useStyles = createUseStyles({
  actionsList: {
    display: "flex",
    alignItems: "flex-end",
  },
  action: {
    color: "#F35B5E",
    "&:hover": {
      cursor: "pointer",
      color: "#CB3337",
    },
  },
});

const DeleteAction = ({ pollId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDeleting, setDeleting] = useState(false);

  const handleClick = () => {
    if (!isDeleting && window.confirm("Are you sure? This cannot be undone.")) {
      setDeleting(true);
      dispatch(destroyPoll(pollId))
        .then(() => {
          window.location.href = "/polls";
        })
        .catch(() => setDeleting(false));
    }
  };

  return (
    <div className={classes.action} onClick={handleClick}>
      Delete Poll
    </div>
  );
};

const PollActionsList = ({ poll }) => {
  const classes = useStyles();

  const settings = useSelector((state) => state.settings);

  if (
    !settings.attributes.admin &&
    settings.attributes.id !== poll.attributes.userId
  ) {
    return null;
  } else {
    return (
      <div className="kt-portlet">
        <div className={classnames("kt-portlet__body", classes.actionsList)}>
          <DeleteAction pollId={poll.id} />
        </div>
      </div>
    );
  }
};

export default PollActionsList;
