import { GET, POST, PATCH, DELETE } from "../util/apiHelpers";
import normalize from "json-api-normalizer";
import queryString from "query-string";
import { batch } from "react-redux";
import { mapKeysToSnakeCase, mapKeysToCamelCase } from "../util/formatHelpers";
import { receiveUsers } from "./userActions";
import { receiveGroups } from "./groupActions";
import { receiveComments } from "./commentActions";

export const RECEIVE_POLLS = "RECEIVE_POLLS";
export const REMOVE_POLL = "REMOVE_POLL";

export const receivePolls = (polls) => ({
  type: RECEIVE_POLLS,
  payload: polls,
});

export const removePoll = (id) => ({
  type: REMOVE_POLL,
  payload: id,
});

export const createPoll = (data) => (dispatch) => {
  return POST("/api/v1/polls", mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receivePolls(normalized.poll));

    return normalized.poll[Object.keys(normalized.poll)[0]];
  });
};

export const destroyPoll = (id) => (dispatch) =>
  DELETE(`/api/v1/polls/${id}`).then((res) => {
    dispatch(removePoll(id));

    return res.data.id;
  });

export const fetchPolls = (params) => (dispatch) => {
  const paramsStr = queryString.stringify(mapKeysToSnakeCase(params));

  return GET(`/api/v1/polls?${paramsStr}`).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receivePolls(normalized.poll));
      dispatch(receiveUsers(normalized.user));
      dispatch(receiveGroups(normalized.group));
    });

    return {
      items: normalized.poll || {},
      ...mapKeysToCamelCase(res.data.meta || {}),
    };
  });
};

export const fetchPoll = (pollId) => (dispatch) =>
  GET(`/api/v1/polls/${pollId}`).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receivePolls(normalized.poll));
      dispatch(receiveComments(normalized.comment));
      dispatch(receiveUsers(normalized.user));
      dispatch(receiveGroups(normalized.group));
    });

    return normalized.poll;
  });

export const fetchRandomNewPoll = (data) => (dispatch) => {
  return GET(`/api/v1/polls/random_new_poll`, data).then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receivePolls(normalized.poll));
      dispatch(receiveComments(normalized.comment));
      dispatch(receiveUsers(normalized.user));
      dispatch(receiveGroups(normalized.group));
    });

    return normalized.poll;
  });
};

export const submitResponse = (data) => (dispatch) =>
  PATCH(
    `/api/v1/polls/${data.pollId}/submit_response`,
    mapKeysToSnakeCase(data)
  ).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receivePolls(normalized.poll));

    return normalized.poll;
  });

export const destroyResponse = (pollId) => (dispatch) =>
  PATCH(`/api/v1/polls/${pollId}/destroy_response`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receivePolls(normalized.poll));

    return normalized.poll;
  });
