import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";

import { search, styles } from "./search/utils";
import Input from "./search/Input";
import Results from "./search/Results";

const Search = () => {
  const classes = styles({});

  const [products, setProducts] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);

  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query && query.length >= 0) {
      setCategoriesLoaded(false);
      setProductsLoaded(false);
      performSearch(query);
    } else {
      setCategories([]);
      setCategoriesLoaded(false);
      setProducts([]);
      setProductsLoaded(false);
    }
  }, [query]);

  const performSearch = useCallback(
    debounce((name) => {
      search("productCategory", name, setCategories).then(() =>
        setCategoriesLoaded(true)
      );
      search("vendorProduct", name, setProducts).then(() =>
        setProductsLoaded(true)
      );
    }, 500),
    []
  );

  const loaded = productsLoaded && categoriesLoaded;
  const show = open && loaded;

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };

  return (
    <div
      className={`form-group ${classes.searchContainer}`}
      onBlur={handleBlur}
      style={{ width: '100%' }}
    >
      <Input {...{ loaded, query, setOpen, setQuery }} />
      <Results {...{ show, products, categories }} />
    </div>
  );
};

export default Search;
