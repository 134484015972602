import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";

import map from "lodash/map";
import Description from "./Description";
import AvatarIcon from "../../General/AvatarIcon";
import { makeGetSubcomments } from "../../../selectors/commentSelectors";
import Like from "../../General/Like";
import CommentField from "../../General/CommentField";
import ProfileHover from "../../Shared/ProfileHover/ProfileHover";

const useStyles = createUseStyles({
  container: {
    marginTop: 12,
    marginBottom: 12,
  },
  commentButtonContainer: {
    display: "flex",
    width: 140,
    alignItems: "center",
    marginLeft: 10,
    height: 40,
  },
  description: {
    backgroundColor: "#F4FAFE",
    borderRadius: "8px",
    marginLeft: 12,
    padding: "12px 12px 0px 12px",
    width: "100%",
  },
  commentButton: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 500,
    color: "#48465B",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAEEF3",
    },
  },
  mainCommentContainer: {
    display: "flex",
  },
  subCommentsContainer: {
    marginLeft: 48,
  },
  replyText: {
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 300,
    color: "#595D6E",
    paddingLeft: 4,
    paddingRight: 4,
  },
});

const Comment = (props) => {
  const classes = useStyles();
  const { subjectId, userId, liked, totalLikes } = props.attributes;
  const settings = useSelector((state) => state.settings);
  const getSubComments = useSelector((state) => makeGetSubcomments(state));
  const subComments = getSubComments(props.id);
  const [showReply, setShowReply] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.mainCommentContainer}>
        <div>
          <ProfileHover {...{ userId }}>
            <AvatarIcon height={45} avatarId={userId} />
          </ProfileHover>
        </div>
        <div className={classes.description}>
          <Description
            {...props}
            editable={settings.id === userId || settings.attributes.admin}
            topicId={subjectId}
            category="comment"
          >
            <div className={classes.commentButtonContainer}>
              <div className={classes.commentButton}>
                <Like id={props.id} totalLikes={totalLikes} liked={liked} />
              </div>
              <div
                className={classes.commentButton}
                style={{
                  marginLeft: 6,
                  marginRight: 6,
                  alignItems: "center",
                  fontSize: 20,
                  paddingLeft: 7,
                  paddingRight: 4,
                  color: "#595D6E",
                }}
                onClick={() => setShowReply(!showReply)}
              >
                <i className="flaticon-reply"></i>
                <div className={classes.replyText}>reply</div>
              </div>
            </div>
          </Description>
        </div>
      </div>
      <div className={classes.subCommentsContainer}>
        {showReply && (
          <CommentField
            topicId={subjectId}
            parentCommentId={props.id}
            setShowReply={setShowReply}
          />
        )}
        {map(subComments, (comment) => (
          <Comment
            {...comment}
            key={comment.id}
            subjectType={props.subjectType}
          />
        ))}
      </div>
    </div>
  );
};

export default Comment;
