import React from "react";
import { useSelector } from "react-redux";

import PollResponseForm from "./PollResponseForm";
import PollResultsItem from "./PollResultsContent";

import moment from "moment";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  container: {
    width: "100%",
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
});

const Poll = ({ id }) => {
  const poll = useSelector((state) => state.polls.items[id]);
  const classes = useStyles();
  if (!poll) {
    return null;
  }

  const { takenByUser, closesAt } = poll.attributes;

  const PollContent =
    takenByUser || moment(closesAt).isBefore(moment())
      ? PollResultsItem
      : PollResponseForm;

  return (
    <div
      className={classnames("kt-portlet", classes.container)}
    >
      <PollContent poll={poll} />
    </div>
  );
};

export default Poll;
