import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";

import { fetchVendorProduct } from "../../../actions/vendorProductActions";
import ProductHeader from "../general/ProductHeader";

import About from "./show/About";
import Reviews from "./show/Reviews";

const useStyles = createUseStyles({
  header: {
    color: "#595d6e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    letterSpacing: "0.07px",
    lineHeight: "24px",
    padding: "20px",
    borderBottom: 0,
    backgroundColor: "white",
    position: "-webkit-sticky",
    position: "sticky",
    height: "auto",
    right: 0,
    top: 70,
    zIndex: 1,
    borderRadius: "4px 4px 0 0",
    transition: "height 0.3s ease",
    "@media (minWidth: 950px)": {
      overflowY: "scroll",
      height: "calc(100vh - 60px)",
    },
  },
  body: {
    borderRadius: "0 0 4px 4px",
    padding: 24,
  },
  unorderedList: {
    padding: "4px 0 0 12px",
    listStyleType: "none",
    "& li+li": {
      paddingTop: "10px",
    },
  },
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 22,
    color: "#263238",
    marginBottom: 20,
  },
  sectionSubtitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    color: "#263238",
    marginBottom: 12,
  },
  infoTitle: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    color: "#455A64",
    marginBottom: 20,
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 16,
    color: "#455A64",
  },
  listItem: {
    fontSize: 18,
    lineHeight: "20px",
    "& a": {
      color: "#367BA6",
    },
  },
  listIcon: {
    marginTop: 2,
    fontSize: 22,
    color: "#000000",
    marginRight: 8,
    width: 22,
    textAlign: "center",
  },
  ratingItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 450,
    width: "100%",
    fontSize: 18,
    color: "#292E33",
    fontWeight: 500,
    marginBottom: 12,
  },
  nav: {
    listStyleType: "none",
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    padding: "0 0 0 7px",
  },
  navItem: {
    float: "left",
    padding: "12px 12px 4px 12px",
    cursor: "pointer",
  },
  navLink: {
    paddingBottom: "2px",
    "&:hover": {
      color: "#263238",
      borderBottom: "2px solid #F35B5E",
    },
  },
});

const Show = ({
  vendorProductSlug,
  vendorProductId,
  reviewIndexUrl,
  viewAllReviews,
  specificReviewSlug,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loaded } = useSelector((state) => state.vendorProducts);

  useEffect(() => {
    if (!loaded) {
      dispatch(
        fetchVendorProduct(vendorProductSlug, { allReviews: viewAllReviews })
      );
    }
  }, [loaded, dispatch]);

  const vendorProduct = useSelector(
    (state) => state.vendorProducts.items[vendorProductId]
  );

  const sections = [];

  if (!viewAllReviews) {
    sections.push(<About key={"about"} vendorProduct={vendorProduct} />);
  }

  sections.push(
    <Reviews
      key={"reviews"}
      vendorProduct={vendorProduct}
      viewAllReviews={viewAllReviews}
      reviewIndexUrl={reviewIndexUrl}
      specificReviewSlug={specificReviewSlug}
    />
  );

  return (
    loaded && (
      <React.Fragment>
        <div
          className={`kt-portlet kt-portlet__body ${classes.header}`}
          style={{
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                msFlexDirection: "row",
              }}
            >
              <ProductHeader
                product={vendorProduct}
                options={{ justifyContent: "center", shrinkLogo: true, reviewLink: true }}
              />
            </Col>
            <Col xs={12} style={{ marginTop: -16, padding: 0 }}>
              <ul className={classes.nav}>
                <li className={classes.navItem}>
                  <a
                    href={`${viewAllReviews ? vendorProduct.attributes.pageUrl : ""}#about`}
                    className={classes.navLink}
                  >
                    About
                  </a>
                </li>
                {Object.keys(vendorProduct.attributes.reviews || {}).length > 0 && (
                  <li className={classes.navItem}>
                    <a href={reviewIndexUrl} className={classes.navLink}>
                      All Reviews
                    </a>
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        </div>
        <div className={`kt-portlet kt-portlet__body ${classes.body}`}>
          {sections}
        </div>
      </React.Fragment>
    )
  );
};

export default Show;
