import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import withGlobalProviders from "../General/withGlobalProviders";
import { Row, Col } from "react-grid-system";
import PollActionsList from "./show/PollActionsList";
import PollSubject from "./show/PollSubject/PollSubject";
import PollReplyCard from "./show/PollReplyCard";
import CommentsList from "./show/CommentsList";

import { fetchPoll } from "../../actions/pollActions";

import map from "lodash/map";

const Show = ({ id }) => {
  const dispatch = useDispatch();

  const poll = useSelector((state) => state.polls.items[id]);

  useEffect(() => {
    dispatch(fetchPoll(id));
  }, [dispatch]);

  const renderContent = () =>
    map(
      [PollActionsList, PollSubject, PollReplyCard, CommentsList],
      (SectionComponent, key) =>
        React.createElement(SectionComponent, { key, poll })
    );

  return (
    <Row>
      <Col xs={12}>{poll && renderContent()}</Col>
    </Row>
  );
};

export default withGlobalProviders(Show);
