import React from "react";
import { createUseStyles } from "react-jss";

import ProductLogo from "../general/ProductLogo";
import ProductHeader from "../general/ProductHeader";

const useStyles = createUseStyles({
  cardStyle: {
    padding: 24,
    height: 200,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    fontFamily: "Poppins",
  },
});

const IndexItem = ({ product }) => {
  const classes = useStyles();

  return (
    <li className={`kt-portlet kt-portlet__body ${classes.cardStyle}`}>
      <ProductHeader
        product={product}
        options={{ showDescription: true, titleLink: true}}
      />
    </li>
  );
};

export default IndexItem;
