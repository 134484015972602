import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  listItem: {
    width: "100%",
    cursor: "pointer",
    padding: "0 24px",
    height: 45,
    lineHeight: "45px",
    fontFamily: "Poppins",
    "&:hover": {
      backgroundColor: "#EAEEF3",
      textDecoration: "underline",
      "text-decoration-color": "#48465B !important",
      "-webkit-text-decoration-color": "#48465B !important",
    },
    "&:active": {
      backgroundColor: "#E3F5FA",
    },
    "& a": {
      color: "#48465B",
      "text-decoration-color": "#48465B !important",
      "-webkit-text-decoration-color": "#48465B !important",
      "&:hover": {
        "text-decoration-color": "#48465B !important",
        "-webkit-text-decoration-color": "#48465B !important",
      },
    },
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  categoryName: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 500,
    color: "#48465B",
  },
  vendorCount: {
    fontSize: 14,
    fontWeight: 300,
    color: "#212121",
    "font-variant-numeric": "tabular-nums lining-nums",
    "text-decoration-color": "#212121 !important",
    "-webkit-text-decoration-color": "#212121 !important",
  },
  vendorInfo: {
    fontSize: 14,
    fontWeight: 300,
    color: "#212121",
    "text-decoration-color": "#212121 !important",
    "-webkit-text-decoration-color": "#212121 !important",
  },
  clear: {
    clear: "both",
  },
});

const Item = ({ category }) => {
  const classes = useStyles();
  return (
    <li className={classes.listItem}>
      <a href={category.attributes.url}>
        <div className={classes.container}>
          <div className={classes.categoryName}>{category.attributes.name}</div>
          <div className={classes.vendorInfo}>
            <span className={classes.vendorCount}>
              {category.attributes.totalProducts}
            </span>{" "}
            vendor
            {category.attributes.totalProducts !== 1 ? (
              "s"
            ) : (
              <div style={{ width: 7, display: "inline-block" }}>&nbsp;</div>
            )}
          </div>
        </div>
      </a>
    </li>
  );
};

export default Item;
