import React from "react";

const Category = ({ item }) => {
  return (
    <li tabIndex={0} style={{ fontSize: 14 }}>
      <a href={item.attributes.url} style={{ color: "#595d6e" }}>
        {item.attributes.name}
      </a>
    </li>
  );
};

export default Category;
