import { Button, makeStyles } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { removeParticipant } from '../../../../../actions/conversationActions';
import { openSnackbar } from '../../../../../actions/uiActions';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(2),
    boxShadow: "none",
    float: "right",
  },
  rightButton: {
    marginLeft: theme.spacing(1)
  }
}));

const innerTheme = (theme) => createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      main: "#f45b5f"
    }
  }
});

export default function RemoveParticipant({ conversation, user, setRemoving }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const onCancel = () => { setConfirm(false); };

  const onRemove = () => { setConfirm(true); };

  useEffect(() => {
    setRemoving && setRemoving(confirm);
  }, [confirm, setRemoving]);

  const onConfirmRemove = () => {
    if (isSubmitting) return;

    const submissionData = {
      conversation_id: conversation.id,
      participant_id: user.id
    };

    setSubmitting(true);
    dispatch(removeParticipant(submissionData))
    .then(() => {
      setSubmitting(false);
      onCancel();
    })
    .catch(() => {
      dispatch(openSnackbar("Server Error"));
      setSubmitting(false);
    });
  };

  return (
    (confirm) ? (
      <ThemeProvider theme={innerTheme}>
        <Button
          variant="outlined"
          color="secondary"
          className={classnames(classes.button, classes.rightButton)}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={onConfirmRemove}
          disabled={isSubmitting}
        >
          Remove
        </Button>
      </ThemeProvider>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onRemove}
      >
        Remove
      </Button>
    )
  );
}
