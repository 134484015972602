import React from "react";
import { styles } from "../utils";

const Product = ({ item }) => {
  const classes = styles({});

  return (
    <li tabIndex={0} style={{ fontSize: 14 }}>
      <a href={item.attributes.pageUrl} style={{ color: "#595d6e" }}>
        <img className={classes.listItemLogo} src={item.attributes.logoUrl} />
        {item.attributes.name}
      </a>
    </li>
  );
};

export default Product;
