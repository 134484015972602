import normalize from "json-api-normalizer";
import { batch } from "react-redux";

import { GET, PATCH } from "../util/apiHelpers";
import { mapKeysToSnakeCase } from "../util/formatHelpers";

import { receiveGroups } from "./groupActions";

export const RECEIVE_SETTINGS = "RECEIVE_SETTINGS";

const receiveSettings = (setting) => ({
  type: RECEIVE_SETTINGS,
  payload: setting,
});

export const fetchSettings = () => (dispatch) => {
  return GET("/api/v1/settings").then((res) => {
    const normalized = normalize(res.data);

    batch(() => {
      dispatch(receiveSettings(normalized.setting));
      dispatch(receiveGroups(normalized.group));
    });
  });
};

export const updateSettings = (data) => (dispatch) => {
  return PATCH("/api/v1/settings", mapKeysToSnakeCase(data)).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receiveSettings(normalized.setting));
  });
};

export const changeChapter = (data) => (dispatch) => {
  return PATCH("/api/v1/settings/change_chapter", data).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receiveSettings(normalized.setting));
  });
}
