import React from "react";
import { createUseStyles } from "react-jss";
import map from "lodash/map";
import find from "lodash/find";

import ProductReview from "../../general/ProductReview";
import StarRatingDisplay from "../../general/StarRatingDisplay";

const useStyles = createUseStyles({
  unorderedList: {
    padding: "4px 0 0 12px",
    listStyleType: "none",
    "& li+li": {
      paddingTop: "10px",
    },
  },
  sectionTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: 22,
    color: "#263238",
    marginBottom: 16,
  },
  sectionSubtitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    color: "#263238",
    marginBottom: 10,
  },
  text: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    color: "#455A64",
  },
  listItem: {
    fontSize: 18,
    lineHeight: "20px",
    "& a": {
      color: "#367BA6",
    },
  },
  listIcon: {
    marginTop: 2,
    fontSize: 22,
    color: "#000000",
    marginRight: 8,
    width: 22,
    textAlign: "center",
  },
  infoTitle: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    color: "#455A64",
    marginBottom: 20,
  },
  ratingItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    fontSize: 18,
    color: "#292E33",
    marginBottom: 12,
    fontWeight: 300,
    maxWidth: 350,
  },
});

const ViewAllButton = ({ classes, reviews, reviewIndexUrl, vendorProduct }) => {
  return (
    <div
      className={classes.ratingItem}
      style={{
        marginBottom: 20,
        justifyContent: "center",
        maxWidth: 450,
        fontWeight: 500,
      }}
    >
      <a
        href={
          reviews.length > 0
            ? reviewIndexUrl
            : vendorProduct.attributes.pageUrl
            ? `${vendorProduct.attributes.pageUrl}/review`
            : ""
        }
      >
        <button className={`btn btn-sm btn-outline-brand-secondary`}>
          {reviews.length > 0 ? "View All" : "Be The First To Review"}
        </button>
      </a>
    </div>
  );
};

const Reviews = ({
  vendorProduct,
  viewAllReviews,
  reviewIndexUrl,
  specificReviewSlug,
}) => {
  const classes = useStyles();

  let reviews = Object.values(vendorProduct.attributes.reviews || {});

  if (!!specificReviewSlug) {
    reviews = [
      find(Object.values(vendorProduct.attributes.reviews), function (review) {
        return review.attributes.toParam === specificReviewSlug;
      }),
    ];
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className={classes.sectionTitle}>
        {reviews.length > 0 ? (
          <React.Fragment>
            {vendorProduct.attributes.name} Reviews
          </React.Fragment>
        ) : (
          <React.Fragment>There are no reviews yet!</React.Fragment>
        )}
      </div>
      {reviews.length > 0 && !viewAllReviews && (
        <div className={classes.infoTitle}>
          Showing the most recent <strong>{reviews.length}</strong> of{" "}
          <strong>
            {vendorProduct.attributes.vendorProductReviewsCount || 0}
          </strong>{" "}
          reviews.
        </div>
      )}
      <div className={classes.ratingItem} style={{ fontWeight: 600 }}>
        <div>Overall</div>
        <StarRatingDisplay rating={vendorProduct.attributes.overallRating} />
      </div>
      <div className={classes.ratingItem}>
        <div>Ease of Use</div>
        <StarRatingDisplay rating={vendorProduct.attributes.easeOfUseRating} />
      </div>
      <div className={classes.ratingItem}>
        <div>Customer Support</div>
        <StarRatingDisplay
          rating={vendorProduct.attributes.customerSupportRating}
        />
      </div>
      <div className={classes.ratingItem} style={{ marginBottom: 20 }}>
        <div>Value for Money</div>
        <StarRatingDisplay
          rating={vendorProduct.attributes.productValueRating}
        />
      </div>
      {(!viewAllReviews || (viewAllReviews && !!specificReviewSlug)) && (
        <ViewAllButton
          classes={classes}
          reviews={reviews}
          reviewIndexUrl={reviewIndexUrl}
          vendorProduct={vendorProduct}
        />
      )}
      {map(reviews, (review) => (
        <ProductReview
          key={review.attributes.id}
          review={review}
          reviewIndexUrl={reviewIndexUrl}
        />
      ))}
      {(!viewAllReviews || (viewAllReviews && !!specificReviewSlug)) &&
        reviews.length > 0 && (
          <ViewAllButton
            classes={classes}
            reviews={reviews}
            reviewIndexUrl={reviewIndexUrl}
            vendorProduct={vendorProduct}
          />
        )}
    </div>
  );
};

export default Reviews;
