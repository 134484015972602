import React from "react";
import map from "lodash/map";

import { styles } from "../utils";

const NoResults = () => <li className="no_results">No results!</li>;

const Items = ({ items, Component }) => {
  if (items.length === 0) return <NoResults />;
  return map(items, (item) => <Component key={item.id} {...{ item }} />);
};

const Section = ({ title, items, Component }) => {
  const classes = styles({});

  return (
    <div>
      <div className={classes.sectionTitle}>{title}</div>
      <ul className={classes.unorderedList}>
        {<Items {...{ items, Component }} />}
      </ul>
    </div>
  );
};

export default Section;
