import React from "react";
import Skeleton from "react-loading-skeleton";
import { createUseStyles } from "react-jss";
import times from "lodash/times";

const useStyles = createUseStyles({
  container: {
    borderTop: "1px solid #ECEDF2",
    padding: "0 13px",
  },
  descriptionIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  statusItemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  statusItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "@media screen and (max-width: 600px)": {
    descriptionIcons: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
});

const SkeletonTopic = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className="kt-notification-v2__item">
        <Skeleton circle={true} height={50} width={50} />
        <div className="kt-notification-v2__itek-wrapper">
          <div className="kt-notification-v2__item-title">
            <Skeleton width={300} />
          </div>
          <div
            className={`kt-notification-v2__item-desc ${classes.descriptionIcons}`}
          >
            <Skeleton width={400} />
          </div>
        </div>
        <div className={classes.statusItemsContainer}>
          <div className={classes.statusItem}>
            <Skeleton width={100} />
          </div>
          <div className={classes.statusItem}>
            <Skeleton width={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonTopics = () => (
  <ul style={{ padding: 0 }}>
    {times(5, () => (
      <SkeletonTopic />
    ))}
  </ul>
);

export default SkeletonTopics;
